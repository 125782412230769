import * as React from "react";
import { css, withTheme } from "@delight-js/react";

import Hero from "../components/Hero";
import Button from "../components/Button";
import Grid from "../components/layout/Grid";
import Row from "../components/layout/Row";
import ContentBox from "../components/layout/ContentBox";
import ContentCell from "../components/layout/ContentCell";
import InquiryForm from "../components/form/InquiryForm";
import Link from "../components/Link";
import { P } from "../components/texts";
import ldJsonBreadCrumb from "../helpers/ldJsonBreadCrumb";

const ContactPage = withTheme(({ theme, ...props }) => {
  const {
    pageData: { hero = {}, content, locale, breadCrumbs },
    translations,
  } = props.pageContext;

  return (
    <React.Fragment>
      {!!hero && (
        <Hero
          overline={hero.overline}
          headline={hero.headline}
          subline={hero.subline}
          image={hero.image?.localFile}
        />
      )}
      <ContentBox content={content} css={css`$mt14;`(theme)} locale={locale} />
      <Grid css={css`$mb10;`(theme)}>
        <Row>
          <ContentCell
            css={css`
              display: flex;
              justify-content: space-around;
            `}
          >
            <Button as={Link} url="tel:+4917663054781">
              {translations.callMe}
            </Button>
          </ContentCell>
        </Row>
      </Grid>
      <Grid css={css`$mb26;`(theme)}>
        <Row>
          <ContentCell>
            <P css={css`$mb4;`(theme)}>{translations.writeMe}</P>
            <InquiryForm translations={translations} />
          </ContentCell>
        </Row>
      </Grid>

      {!!breadCrumbs && !!breadCrumbs.length && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: ldJsonBreadCrumb(breadCrumbs) }}
        />
      )}
    </React.Fragment>
  );
});

export default ContactPage;
