import * as React from "react";
import { css, withTheme } from "@delight-js/react";

const Label = withTheme(({ theme, children, ...props }) => (
  <label
    css={css`
      position: relative;
      border: 0;
      padding: 1em 0 0;
      display: block;

      span {
        position: absolute;
        left: 0;
        top: 1.2em;
        transition: transform 0.2s, color 0.2s;
        transform-origin: 0 0;
        pointer-events: none;
      }
      &:focus-within {
        span {
          color: $color-spot1;
        }
      }

      textarea,
      div {
        display: block;
        width: 100%;
        background: transparent;
        border: 0;
        border-bottom: 2px solid $color-text;
        padding: 0 0 1em;
        outline: 0;
        transition: border-color 0.25s;
        resize: none;
        $textBody;
        $textBody1;

        &:focus,
        &:not(:placeholder-shown) {
          + span {
            transform: translate3d(0, -23px, 0) scale(0.5);
          }
        }

        &:focus {
          border-color: $color-spot1;
        }
      }
      div {
        position: absolute !important;
        right: calc(100% + 10px);
        top: 0;
        width: 100%;
        border: 0;
        padding: 0;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        // white-space: nowrap; // added line
        opacity: 0;
        pointer-events: none;
      }
    `(theme)}
  >
    {children}
  </label>
));

function TextAutoGrowFormik({
  field,
  label,
  placeholder,
  maxHeight = 150,
  className,
  ...others
}) {
  const dummy = React.useRef();
  const [height, setHeight] = React.useState("0px");
  React.useEffect(() => {
    setHeight(field?.value ? `${dummy.current.offsetHeight}px` : `auto`);
    return;
  }, [field.value]);
  return (
    <Label className={className}>
      <div
        ref={dummy}
        dangerouslySetInnerHTML={{
          __html:
            (field?.value || "")
              .replace(/(?:\r\n|\r|\n)$/g, "<br />\u00a0")
              .replace(/(?:\r\n|\r|\n)/g, "<br />") || "\u00a0",
        }}
      ></div>
      <textarea
        {...field}
        rows="1"
        placeholder=" "
        style={{ maxHeight: `${maxHeight}px`, height }}
      />
      <span>{label}</span>
    </Label>
  );
}

export default TextAutoGrowFormik;
