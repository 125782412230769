import * as React from "react";
import { css, withTheme } from "@delight-js/react";
import { Formik, Form, Field } from "formik";
import { navigate } from "gatsby";
import Button from "../Button";
import TextInputFormik from "./TextInputFormik";
import TextAutoGrowFormik from "./TextAutoGrowFormik";
import ChoiceInputFormik from "./ChoiceInputFormik";

const InquiryForm = withTheme(({ theme, translations }) => {
  async function handleFormSubmit({ phone, ...values }, { setSubmitting }) {
    setSubmitting(true);
    // honepot
    if (!phone || !phone.length) {
      await fetch(
        "https://hook.integromat.com/o8jvtosqucxoy63tygqwel7ns69eok9e",
        {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(values), // body data type must match "Content-Type" header
        }
      );
      // .then((response) => response.json())
      // .then((data) => console.log(data));
    }
    navigate(translations.thankYouPath || "thank-you");
  }

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        message: "",
        phone: "", // as a honeypot
        acceptProcessing: false,
      }}
      onSubmit={handleFormSubmit}
    >
      {({
        // values,
        // errors,
        // touched,
        // handleChange,
        // handleSubmit,
        isSubmitting,
        // ...props
        /* and other goodies */
      }) => (
        <Form autoComplete="off">
          <Field
            component={TextInputFormik}
            label={translations.fieldName}
            name="name"
            required="required"
            css={css`
              margin-bottom: 1em;
            `}
          />
          <Field
            label={translations.fieldEmail}
            type="email"
            name="email"
            required="required"
            component={TextInputFormik}
            css={css`
              margin-bottom: 1em;
            `}
          />
          <div className="visually-hidden">
            <Field
              name="phone"
              type="input"
              tabIndex="-1"
              autoComplete="nope"
            />
          </div>
          <Field
            label={translations.fieldMessage}
            name="message"
            required="required"
            component={TextAutoGrowFormik}
            css={css`
              margin-bottom: 2em;
            `}
          />
          <Field
            label={translations.consent}
            name="acceptProcessing"
            type="checkbox"
            required="required"
            component={ChoiceInputFormik}
            css={css`
              $my10;
              margin-bottom: 2em;
            `(theme)}
          />
          <Button type="submit" disabled={isSubmitting}>
            {translations.submit}
          </Button>
        </Form>
      )}
    </Formik>
  );
});

export default InquiryForm;
